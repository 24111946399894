
@import '~bootstrap/scss/functions';
$red:     #CC1622 !default;
$border-radius:               .75rem !default;
$border-radius-sm:            .5rem !default;
$border-radius-lg:            .8rem !default;
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
$font-family-sans-serif: 'Poppins', sans-serif;
$font-size-base: 1rem;
$line-height-base: 1.6;
$enable-negative-margins: true !default;
$spacer: 1rem !default;
$spacers: (
    0: 0,
    1: $spacer * .25,
    2: $spacer * .5,
    3: $spacer,
    4: $spacer * 1.5,
    5: $spacer * 3,
    6: $spacer * 3.5,
    7: $spacer * 4,
    8: $spacer * 4.5,
    9: $spacer * 5,
    10: $spacer * 5.5,
    11: $spacer * 6,
    12: $spacer * 6.5,
    13: $spacer * 7,
    14: $spacer * 7.5,
    15: $spacer * 8,
    16: $spacer * 8.5,
    17: $spacer * 9,
    18: $spacer * 9.5,
    19: $spacer * 10,
    20: $spacer * 10.5,
    21: $spacer * 11,
    22: $spacer * 11
)!default;

@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/root';


@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/containers';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/helpers';



@import "~bootstrap/scss/utilities/api";

$navbar-toggler-border-radius: .25rem;

$accordion-border-color: $danger;
$accordion-button-active-bg: $danger;
$accordion-button-active-color: #fff;
$accordion-bg: $danger;
$accordion-border-width:      1px;
$accordion-button-focus-box-shadow:       0;
$accordion-icon-width:                    1.75rem;
$accordion-icon-transition:               transform .3s ease-in-out;
$accordion-icon-active-color: $white;
$accordion-button-icon:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-color}'><path fill-rule='evenodd' d='M2.89,3.87,8,9l5.11-5.11,1.57,1.58L8,12.13,1.32,5.45Z'/></svg>");
$accordion-button-active-icon:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-active-color}'><path fill-rule='evenodd' d='M2.89,3.87,8,9l5.11-5.11,1.57,1.58L8,12.13,1.32,5.45Z'/></svg>");
$navbar-nav-link-padding-x: .75rem!important;
$input-focus-box-shadow:      none;
$navbar-padding-y:                  $spacer * 1;
$navbar-light-hover-color: $danger;
.navbar-light .navbar-nav .nav-link.active {
    color: #fff!important;
    background-color: #CC1622;
    border-radius: 0.75rem;
}

.navbar-nav .nav-link {
    padding-left: 1rem!important;
    padding-right: 1rem!important;
}

// $border-radius-12: 0.75rem!default;
// $utilities: map-merge(
//   $utilities,
//   (
//     "rounded": map-merge(
//       map-get($utilities, "rounded"),
//       (
//         values: map-merge(
//           map-get(map-get($utilities, "rounded"), "values"),
//           (12: $border-radius-12),
//         ),
//       ),
//     ),
//   )
// );

@import '~bootstrap/scss/bootstrap';